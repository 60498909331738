import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'

const createOption = (origin, path, data) => {
  const login_url = origin || window.location.origin
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: true,
    data,
    url: login_url + path,
  }
}

const loginService = {
  can: '/login-service/can',
  who: '/login-service/who',
  login: '/login-service/login',
}

const ok = 200

const useLogin = ({ app, origin, bypass = false }) => {
  const [login, setLogin] = useState(null)
  const [permission, setPermission] = useState([])
  const [email, setEmail] = useState('')

  const getPermission = useCallback(async () => {
    try {
      const options = createOption(origin, loginService.can, { app })
      const { status, data: privilege } = await axios(options)

      if (status === ok) {
        const optionsWho = createOption(origin, loginService.who, { app })
        const { data: email } = await axios(optionsWho)
        setPermission(privilege)
        setEmail(email)
        setLogin(true)
      } else {
        setLogin(false)
      }
    } catch {
      setLogin(false)
    }
  }, [app, setLogin])

  useEffect(() => {
    if (bypass) {
      setLogin(false)
      return
    }

    getPermission()
  }, [app, bypass, getPermission])

  async function doLogin(credential) {
    if (bypass) {
      setLogin(true)
      setPermission(['all'])
      setEmail('')
      return
    }

    if (credential) {
      const options = createOption(origin, loginService.login, {
        token_id: credential,
      })
      const { status } = await axios(options)
      if (status === ok) {
        await getPermission()
      }
    }
  }

  function doLogout() {
    setLogin(false)
    setPermission([])
    setEmail('')
    const cookies = new Cookies()
    cookies.set('login.sid', '', { path: '/' })
  }

  return [login, doLogin, doLogout, email, permission]
}

export default useLogin
