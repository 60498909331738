import { useCallback, useEffect, useState } from 'react'
import { loader, unloader } from './script-loader'

const useGoogleSignin = ({
  onSuccess = () => {},
  onFailure = () => {},
  onScriptLoadFailure,
  clientId,
}) => {
  const [loaded, setLoaded] = useState(false)

  const handleCredentialResponse = useCallback(
    ({ credential }) => onSuccess(credential),
    [onSuccess],
  )

  useEffect(() => {
    const onLoadFailure = onScriptLoadFailure || onFailure
    loader(
      document,
      'script',
      'google-gsi',
      'https://accounts.google.com/gsi/client',
      () => {
        /* global google */
        google.accounts.id.initialize({
          client_id: clientId,
          callback: handleCredentialResponse,
        })
        setLoaded(true)
      },
      err => {
        onLoadFailure(err)
      },
    )

    return () => {
      unloader(document, 'google-gsi')
    }
  }, [])

  return { loaded }
}

export default useGoogleSignin
