import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import useGoogleSignin from './use-google-signin'

const GoogleSignin = ({ clientId, oneTap = true, onSuccess, onError }) => {
  const { loaded } = useGoogleSignin({
    clientId,
    onSuccess,
    onFailure: onError,
    onScriptLoadFailure: onError,
  })

  useEffect(() => {
    if (loaded) {
      /* global google */
      google.accounts.id.renderButton(document.getElementById('button-gsi'), {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'signin_with',
        shape: 'rectangular',
        logo_alignment: 'left',
      })

      // Google One Tap prompt
      if (oneTap) {
        google.accounts.id.prompt()
      }
    }
  }, [loaded])

  return <div id="button-gsi"></div>
}

GoogleSignin.propTypes = {
  clientId: PropTypes.string.isRequired,
  oneTap: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
}

export default GoogleSignin
